export const PrintContent = (
  userInfo,
  userStoreInfo,
  questionnaireInfo,
  responses
) => `
      <div style="display: flex; align-items: center; margin: 0px 30px">
          <div style="flex:1">
              <img src='/dkt-logo.jpg' alt="logo" style="width:100%"/>
          </div>
          <div style="flex:1;text-align:center;line-height:0px" >
              <h2 style="color: #3643ba">Pre-Delivery Inspection</h2>
              <h5>BS EN 14764 (City & Trekking)</h5>
              <h5>BS EN 14766 (Mountain Cycles)</h5>
              <h5>BS EN14781 (Road Cycles)</h5>
              <h5>BS EN 14765 (Children’s Cycles)</h5>
              <h5>BS EN15194 (Electrically Power Assisted Cycles)</h5>
          </div>
      </div>
      <div style="margin: 0px 50px;">
          <h4 style="text-align:center;margin:0">
              This Document is completed digitally during the course of Pre-Delivery
              Inspection (PDI). If printed, attach to the bicycle when the PDI is
              completed.
          </h4>
          <h4 style="font-weight: 100;text-align:center;margin:0">
              Completion of this document signifies that this bicycle has been
              subject to a full PDI and the checks carried out are detailed in the
              table below. The mechanic’s name and the date of the PDI are also
              detailed for your easy reference.
          </h4>
      </div>
  
      <table style="margin: auto;width: 100%;text-align: center; border-spacing: 10px;">
          <tr>
              <td>
                  <span>
                      <b>MECHANIC NAME: </b>${
                        userInfo?.displayName?.split(" ")[0]
                      }
                  </span>
              </td>
              <td>
                  <span>
                      <b>STORE: </b>${userStoreInfo?.store_name} 
                      <b>DATE: </b>${ new Date().toLocaleDateString()}
                  </span>
              </td>
          </tr>
          <tr>
              <td>
                  <span>
                      <b>BICYCLE SERIAL NUMBER: </b>${
                        questionnaireInfo?.itemInfo?.serialNumber
                      }
                  </span>
              </td>
              <td>
                  <span>
                      <b>BICYCLE MODEL: </b>${
                        questionnaireInfo?.itemInfo?.modelName
                      }
                  </span>
              </td>
          </tr>
          <tr>
              <td>
                  <span>
                      <b>BICYCLE REFERENCE NUMBER: </b>${
                        questionnaireInfo?.itemInfo?.modelId
                      }
                  </span>
              </td>
              <td>
                  <span>
                      <b>LOYALTY/C&C NUMBER: </b>${
                        questionnaireInfo?.itemInfo?.loyaltyCard ||
                        questionnaireInfo?.itemInfo?.orderNumber
                      }
                  </span>
              </td>
          </tr>
      </table>
      <div style="display:flex;flex-wrap: wrap;">
          ${responses
            .map(
              (response) => `
              <div style="flex: 0 0 calc(50% - 20px); display:flex">
                  <div style="border: 1px solid lightgray;flex:3;padding: 5px;text-align: center;"><b>${response.translated_text}</b></div>    
                  <div style="border: 1px solid lightgray;flex:1;padding: 5px;text-align: center;color:#3643ba">${response.response}</div>    
              </div>
          `
            )
            .join("")}
      </div>
      <div style="display:flex;align-items: center;margin-top: 20px;flex-direction: column;">
          <img src='/qr.svg' alt="qr" style="width:20%;max-width:20%"/>
          <h6 style="width:20%;max-width:20%;text-align:center; margin-top:5px"> SCAN TO BOOK YOUR FREE 3 MONTH CHECK UP</h6>
      </div>
  `;
